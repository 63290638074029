import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useStyledDarkMode } from "gatsby-styled-components-dark-mode"

import LogoDesktop from "../assets/svg/logo-one-line.svg"
import ThemeIcon from "../assets/svg/theme.svg"
import { headerDesktopNavLinkFontMixin } from "../assets/styles/fontStyle"
import { paddingDesktopMixin } from "../assets/styles/paddingStyle"
import { PageEnum } from "./layout"

export const HEADER_HEIGHT = "100px"

const HeaderWrapper = styled.header`
  display: none;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: block;
    width: 100%;
    height: ${HEADER_HEIGHT};
    background: ${({ theme }) => theme.color.header};
    backdrop-filter: blur(24px);
  }
`
const NavBar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${paddingDesktopMixin}
`

const LogoLink = styled(Link)`
  display: inline-flex;
`
const Logo = styled(LogoDesktop)`
  width: 226px;
  height: 24px;
  color: ${({ theme }) => theme.color.main};
`

const NavLinks = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const NavLink = styled(({ active, ...rest }) => <Link {...rest} />)`
  ${headerDesktopNavLinkFontMixin}
  padding: 8px 16px;
  text-transform: uppercase;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.hover};
    text-decoration-color: ${({ theme }) => theme.color.hover};
    opacity: 1;
  }
`

const ThemeIconStyled = styled(ThemeIcon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.color.main};
`
const ThemeSwitchButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  margin: 0 12px 4px 12px;

  &:hover {
    ${ThemeIconStyled} {
      color: ${({ theme }) => theme.color.hover};
    }
  }
`

type Props = {
  page?: PageEnum
}

const HeaderDesktop = ({ page }: Props) => {
  const { toggleDark } = useStyledDarkMode()

  return (
    <HeaderWrapper>
      <NavBar>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <NavLinks>
          <NavLink to="/" active={page === PageEnum.PROJECTS}>
            PROJECTS
          </NavLink>
          <NavLink to="/about" active={page === PageEnum.ABOUT}>
            ABOUT
          </NavLink>
          <NavLink to="/contact" active={page === PageEnum.CONTACT}>
            CONTACT
          </NavLink>
          <ThemeSwitchButton onClick={() => toggleDark()}>
            <ThemeIconStyled />
          </ThemeSwitchButton>
        </NavLinks>
      </NavBar>
    </HeaderWrapper>
  )
}

export default HeaderDesktop
