import { css } from "styled-components"

export const paddingMobileMixin = css`
  padding: 0 ${({ theme }) => theme.padding.mobile} 0
    ${({ theme }) => theme.padding.mobile};
`

export const marginMobileMixin = css`
  margin: 0 ${({ theme }) => theme.padding.mobile} 0
    ${({ theme }) => theme.padding.mobile};
`

export const paddingTabletMixin = css`
  padding: 0 ${({ theme }) => theme.padding.tablet} 0
    ${({ theme }) => theme.padding.tablet};
`

export const marginTabletMixin = css`
  margin: 0 ${({ theme }) => theme.padding.tablet} 0
    ${({ theme }) => theme.padding.tablet};
`

export const paddingDesktopMixin = css`
  padding: 0 ${({ theme }) => theme.padding.desktop} 0
    ${({ theme }) => theme.padding.desktop};
`

export const marginDesktopMixin = css`
  margin: 0 ${({ theme }) => theme.padding.desktop} 0
    ${({ theme }) => theme.padding.desktop};
`
