import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
  image: string
  description: string
  pageTitle: string
  title: string
}

const SEO = ({ pageTitle, title, description, image }: SEOProps) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplateSuffix
          defaultDescription: description
          siteUrl: url
        }
      }
    }
  `)

  const defaultImageSrc = "/social-sharing.png"
  const {
    defaultTitle,
    titleTemplateSuffix,
    defaultDescription,
    siteUrl,
  } = site.siteMetadata

  const seo = {
    pageTitle: pageTitle || titleTemplateSuffix,
    titleTemplate: pageTitle ? "%s | " + titleTemplateSuffix : undefined,
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImageSrc,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.pageTitle} titleTemplate={seo.titleTemplate}>
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" content={seo.image} />}

      {seo.url && <meta property="og:url" content={seo.url} />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

SEO.defaultProps = {
  title: null,
  description: null,
  pageTitle: null,
  image: null,
  imageWidth: null,
  imageHeight: null,
}

export default SEO
