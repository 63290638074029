import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStyledDarkMode } from "gatsby-styled-components-dark-mode"

import LogoOneLine from "../assets/svg/logo-one-line.svg"
import ThemeIcon from "../assets/svg/theme.svg"
import {
  buttonLabelMixin,
  headerMobileNavLinkFontMixin,
  contactFollowFontMixin,
  linkMixin,
} from "../assets/styles/fontStyle"
import {
  paddingMobileMixin,
  marginMobileMixin,
  paddingTabletMixin,
  marginTabletMixin,
} from "../assets/styles/paddingStyle"

export const HEADER_HEIGHT = "59px"
export const HEADER_DROPDOWN_HEIGHT = `calc(100vh - ${HEADER_HEIGHT})`

const HeaderWrapper = styled(({ opened, ...rest }) => <header {...rest} />)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${({ opened, theme }) =>
    opened ? theme.color.mainBackground : "none"};
  transition: background-color 0.3s ease-out;

  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: none;
  }
`
interface NavBarProps {
  readonly opened: boolean
}
const NavBar = styled.div<NavBarProps>`
  ${paddingMobileMixin}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT};
  ${({ opened, theme }) => (opened ? "" : `background: ${theme.color.header};`)}
  ${({ opened }) => (opened ? "" : "backdrop-filter: blur(24px);")}
  transition: background-color 0.6s ease-out;
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${paddingTabletMixin}
  }
`

const LogoLink = styled(Link)`
  display: inline-flex;
`

const LogoIcon = styled(LogoOneLine)`
  width: 153px;
  height: 16px;
  color: ${({ theme }) => theme.color.main};
`

const MobileMenuButton = styled.button`
  ${buttonLabelMixin}
  border: none;
  padding: 0;
  width: 85px;
  height: 35px;
  color: ${({ theme }) => theme.color.mainBackground};
  background: ${({ theme }) => theme.color.main};
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const MenuDropdown = styled(({ collapsed, ...rest }) => <div {...rest} />)`
  height: ${({ collapsed }) => (collapsed ? "0" : HEADER_DROPDOWN_HEIGHT)};
  overflow: hidden;
  transition: height 0.3s ease-out;
  position: relative;
`
const EllipseGreenWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const EllipseGreenImage = styled(GatsbyImage)`
  width: 100%;
`

const MenuDropdownContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`

const NavLinks = styled(({ collapsed, ...rest }) => <nav {...rest} />)`
  ${marginMobileMixin}
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  opacity: ${({ collapsed }) => (collapsed ? "0" : "1")};
  transition: opacity 1s ease;

  > :not(:last-child) {
    margin-bottom: 32px;
  }

  > * {
    transform: ${({ collapsed }) => (collapsed ? "translateY(100px)" : "none")};
    transition: transform 1s ease;
  }

  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${marginTabletMixin}
  }
`

const NavLink = styled(Link)`
  ${headerMobileNavLinkFontMixin}
  font-weight: 400;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.hover};
    text-decoration: underline;
  }
`

const ThemeIconStyled = styled(ThemeIcon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.color.main};
`
const ThemeSwitchButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 4px;

  &:hover {
    ${ThemeIconStyled} {
      color: ${({ theme }) => theme.color.hover};
    }
  }
`

const Footer = styled(({ collapsed, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: ${({ collapsed }) => (collapsed ? "0" : "1")};
  transition: opacity 1s ease;
  ${paddingMobileMixin}
  padding-bottom: 74px;
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${paddingTabletMixin}
    padding-bottom: 74px;
  }
`

const FooterTitle = styled.div`
  ${contactFollowFontMixin}
`

const FooterLinks = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 32px 32px;
  grid-template-areas:
    ". ."
    ". .";
`

const FooterLink = styled.a`
  ${linkMixin}

  &:hover {
    color: ${({ theme }) => theme.color.hover};
    text-decoration-thickness: 2px;
  }
`

const HeaderMobile = () => {
  const { isDark, toggleDark } = useStyledDarkMode()
  const [opened, setOpened] = useState(false)

  const handleMobileMenuClick = () => {
    setOpened(!opened)
  }

  const data = useStaticQuery(graphql`
    {
      ellipseGreen: file(relativePath: { eq: "ellipse-green.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <HeaderWrapper opened={opened}>
      <NavBar opened={opened}>
        <LogoLink to="/">
          <LogoIcon />
        </LogoLink>
        <MobileMenuButton onClick={handleMobileMenuClick}>
          {opened ? "CLOSE" : "MENU"}
        </MobileMenuButton>
      </NavBar>
      <MenuDropdown collapsed={!opened}>
        {!isDark && (
          <EllipseGreenWrapper>
            <EllipseGreenImage
              alt=""
              image={data.ellipseGreen.childImageSharp.gatsbyImageData}
            />
          </EllipseGreenWrapper>
        )}
        <MenuDropdownContent>
          <NavLinks collapsed={!opened}>
            <ThemeSwitchButton onClick={() => toggleDark()}>
              <ThemeIconStyled />
            </ThemeSwitchButton>
            <NavLink to="/about" onClick={handleMobileMenuClick}>
              ABOUT
            </NavLink>
            <NavLink
              to="/"
              onClick={handleMobileMenuClick}
              style={{ transitionDelay: "100ms" }}
            >
              PROJECTS
            </NavLink>
            <NavLink
              to="/contact"
              onClick={handleMobileMenuClick}
              style={{ transitionDelay: "200ms" }}
            >
              CONTACT
            </NavLink>
          </NavLinks>
          <Footer>
            <FooterTitle>Follow me 🌈</FooterTitle>
            <FooterLinks>
              <FooterLink
                href="https://www.linkedin.com/in/cgutierrezcorte"
                target="_blank"
              >
                LinkedIn ↗
              </FooterLink>
              <FooterLink
                href="https://dribbble.com/cgutierrez"
                target="_blank"
              >
                Dribbble ↗
              </FooterLink>
              <FooterLink
                href="https://www.instagram.com/allaboutlalo/"
                target="_blank"
              >
                Instagram ↗
              </FooterLink>
              <FooterLink>Twitter ↗</FooterLink>
            </FooterLinks>
          </Footer>
        </MenuDropdownContent>
      </MenuDropdown>
    </HeaderWrapper>
  )
}

export default HeaderMobile
