import React, { ReactNode } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Normalize } from "styled-normalize"
import { animateScroll } from "react-scroll"
import GlobalStyle from "../assets/styles/globalStyle"
import LogoWave from "../assets/svg/logo-wave.svg"
import { ParallaxProvider } from "react-scroll-parallax"

import HeaderMobile, {
  HEADER_HEIGHT as HEADER_HEIGHT_MOBILE,
} from "./header-mobile"
import HeaderDesktop, {
  HEADER_HEIGHT as HEADER_HEIGHT_DESKTOP,
} from "./header-desktop"

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.mainBackground};
  width: 100vw;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: ${HEADER_HEIGHT_MOBILE};
  min-height: calc(100vh - ${HEADER_HEIGHT_MOBILE});
  max-width: ${({ theme }) => theme.screen.max};
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    padding-top: ${HEADER_HEIGHT_DESKTOP};
    min-height: calc(100vh - ${HEADER_HEIGHT_DESKTOP});
  }
`
const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const FixedLogoWave = styled(LogoWave)`
  position: fixed;
  bottom: 20px;
  right: 36px;
  z-index: 1000;
  width: 32px;
  height: 18px;

  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    right: 100px;
  }
`

export enum PageEnum {
  PROJECTS,
  ABOUT,
  CONTACT,
}

type Props = {
  children: ReactNode
  page?: PageEnum
}

export const Layout = ({ children, page }: Props) => {
  return (
    <ParallaxProvider>
      <Container>
        <Normalize />
        <GlobalStyle />
        <Content>
          <HeaderWrapper>
            <HeaderMobile />
            <HeaderDesktop page={page} />
          </HeaderWrapper>
          <Link to="/" onClick={animateScroll.scrollToTop}>
            <FixedLogoWave />
          </Link>
          {children}
        </Content>
      </Container>
    </ParallaxProvider>
  )
}
