import { createGlobalStyle } from "styled-components"

import TelegrafRegularWoff2 from "../fonts/Telegraf-Regular.woff2"
import TelegrafRegularWoff from "../fonts/Telegraf-Regular.woff"
import TelegrafRegularTtf from "../fonts/Telegraf-Regular.ttf"
import TelegrafRegularOtf from "../fonts/Telegraf-Regular.otf"
import TelegrafSemiBoldWoff2 from "../fonts/Telegraf-SemiBold.woff2"
import TelegrafSemiBoldWoff from "../fonts/Telegraf-SemiBold.woff"
import TelegrafSemiBoldTtf from "../fonts/Telegraf-SemiBold.ttf"
import TelegrafSemiBoldOtf from "../fonts/Telegraf-SemiBold.otf"
import TelegrafBoldOtf from "../fonts/Telegraf-Bold.otf"
import TelegrafUltraBoldOtf from "../fonts/Telegraf-UltraBold.otf"

import MonumentExtendedBoldOtf from "../fonts/MonumentExtended-Bold.otf"

export default createGlobalStyle`
    @font-face {
        font-family: 'Telegraf';
        src: url(${TelegrafRegularWoff2}) format("woff2"),
             url(${TelegrafRegularWoff}) format("woff"),
             url(${TelegrafRegularTtf}) format("truetype"),
             url(${TelegrafRegularOtf}) format('opentype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Telegraf';
        src: url(${TelegrafSemiBoldWoff2}) format("woff2"),
             url(${TelegrafSemiBoldWoff}) format("woff"),
             url(${TelegrafSemiBoldTtf}) format("truetype"),
             url(${TelegrafSemiBoldOtf}) format('opentype');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Telegraf';
        src: url(${TelegrafBoldOtf}) format('opentype');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Telegraf';
        src: url(${TelegrafUltraBoldOtf}) format('opentype');
        font-weight: 800;
        font-style: normal;
    }

    @font-face {
        font-family: 'MonumentExtended';
        src: url(${MonumentExtendedBoldOtf}) format('opentype');
        font-weight: 700;
        font-style: normal;
    }

    html {
        font-family: ${({ theme }) => theme.fontFamily.primary};
        font-weight: 400;
        font-style: normal;
        color: ${({ theme }) => theme.color.black};
    }
`
