import { css } from "styled-components"

export const h1Mixin = css`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 80px;
    line-height: 88px;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktopLarge}) {
    font-size: 124px;
    line-height: 128px;
  }
`

export const heroTextFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 48px;
    line-height: 58px;
  }
`

export const h2SemiboldMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 72px;
    line-height: 78px;
  }
`

export const h3Mixin = css`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 48px;
    line-height: 58px;
  }
`

export const h4BoldMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 32px;
    line-height: 34px;
  }
`

export const contactFollowFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 32px;
    line-height: 34px;
  }
`

export const aboutQuoteFontMixin = css`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 32px;
    line-height: 36px;
  }
`
// TODO consolidate contactTitleFontMixin & headerMobileNavLinkFontMixin
export const contactTitleFontMixin = css`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: 700;
  font-size: 34px;
  line-height: 38px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 50px;
    line-height: 56px;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktopLarge}) {
    font-size: 72px;
    line-height: 76px;
  }
`
export const headerMobileNavLinkFontMixin = css`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: 700;
  font-size: 34px;
  line-height: 38px;
  color: ${({ theme }) => theme.color.main};
`

export const pillFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 50px;
    line-height: 54px;
  }
`

export const contactPillFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 32px;
    line-height: 34px;
  }
`

export const buttonLabelMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const featuredProjectsFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const heroButtonFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 20px;
    line-height: 28px;
  }
`

export const headerDesktopNavLinkFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px; // whatever
  line-height: 20px; // whatever
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const linkMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const body1FontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 20px;
    line-height: 22px;
  }
`

export const aboutFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 24px;
    line-height: 34px;
  }
`

export const creditsFontMixin = css`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.color.main};
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    font-size: 16px;
    line-height: 18px;
  }
`
